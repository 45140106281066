import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Layout from "../components/Layout/Layout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import ErrorPage from "../components/404/404"
import { graphql, useStaticQuery } from "gatsby"

const PageNotFound = () => {
  const data = useStaticQuery(graphql`
    query pageNotFOundQuery {
      prismicBlindcv404Page {
        data {
          not_found_page_analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                id
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          title
          description {
            text
            html
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          error_message {
            text
            html
          }
          secondary_image {
            alt
            localFile {
              publicURL
            }
          }

          homepage_link
          homepage_label
        }
      }
    }
  `)

  const errorPage = data.prismicBlindcv404Page.data
  return (
    <div>
      <Layout pageViewAnalyticsData={
          data.prismicBlindcv404Page.data.not_found_page_analytics.document.data
        }>
        <NavigationBar />
        <div className="page-content">
          <ErrorPage data={errorPage} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default PageNotFound
