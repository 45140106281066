import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Markup } from "interweave"
import "./404.scss"
import bluearrow from "../../images/blueleftarrow-up.png"

export default function ErrorPage(props) {
  const primaryImage = getImage(
    props.data.image.localFile.childImageSharp.gatsbyImageData
  )

  return (
    <section className="errorpage">
      <div className="container">
        <div className="row">
          <div className=" col-12 col-md-8 col-lg-7">
            <h1>
              {props.data.title}
              <GatsbyImage
                className="errorimg"
                image={primaryImage}
                alt={props.data.image.alt}
              />
            </h1>
            <h2>
              <Markup content={props.data.description.text}></Markup>
            </h2>
          </div>
          <div className="col-md-5 col-lg-4 pull-right imageCenter">
            <img
              src={props.data.secondary_image.localFile.publicURL}
              alt={props.data.secondary_image.alt}
            />
            <p>
              <Markup content={props.data.error_message.text}></Markup> &nbsp;
              <a href={props.data.homepage_link}>{props.data.homepage_label}</a>
              .
            </p>
            <img alt="" src={bluearrow} className="bluearrowup"></img>
          </div>
        </div>
      </div>
    </section>
  )
}
